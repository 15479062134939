'use client';

import { config } from 'moduleAlias/config';
import { Component, ErrorInfo, ReactNode } from 'react';

const errorBoundaryFailedTranslation = {
	en: 'This component could not be loaded, please try again later.',
	nl: 'Dit onderdeel kon niet worden ingeladen, probeer het later nog eens.',
} as const;

const lang = config.languageCode;
export const ErrorFallback = () => {
	return <p>{errorBoundaryFailedTranslation[lang]}</p>;
};

type ErrorBoundaryProps = { children: ReactNode; fallback: ReactNode };
export class ErrorBoundary extends Component<
	ErrorBoundaryProps,
	{ didCatch: boolean }
> {
	constructor(props: ErrorBoundaryProps) {
		super(props);

		this.state = { didCatch: false };
	}
	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		this.setState({ didCatch: true });
		console.error(error, errorInfo);
	}
	render(): ReactNode {
		return this.state.didCatch ? this.props.fallback : this.props.children;
	}
}
