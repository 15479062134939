// Rijkshuistijl colors:
export const oranje = {
	'100': '#fae6d1',
	'130': '#f8dec3',
	'160': '#f7d6b6',
	'590': '#d16800',
	'620': '#c26000',
	'680': '#a35100',
	'710': '#944a00',
	'750': '#803f00',
	base: '#e17000',
	tint1: '#f6d4b2',
	tint2: '#fbead9',
} as const;
export const hemelblauw = {
	'100': '#d5e9f6',
	'130': '#c9e3f3',
	'160': '#bcdcf0',
	'650': '#006eb3',
	'680': '#0065a3',
	'850': '#002f4d',
	'880': '#00263d',
	'910': '#001c2e',
	'970': '#00090f',
	base: '#007bc7',
	tint1: '#b2d7ee',
	tint2: '#d9ebf7',
} as const;
export const donkerblauw = {
	'730': '#015c89',
	'760': '#01527a',
	'800': '#014465',
	'820': '#013d5b',
	'830': '#013a56',
	'850': '#00334c',
	'860': '#003047',
	'880': '#00293d',
	'910': '#001f2e',
	base: '#01689b',
	tint1: '#cce0f1',
	tint2: '#e5f0f9',
} as const;
export const neutral = {
	'30': '#f7f7f7',
	'50': '#f2f2f2',
	'60': '#f0f0f0',
	'80': '#ebebeb',
	'110': '#e3e3e3',
	'400': '#9a9a9a',
	'600': '#676767',
	'800': '#333333',
	'860': '#242424',
	'920': '#151515',
	base: '#808080',
	black: '#000',
	white: '#fff',
} as const;
export const cbs = {
	'530': '#191345',
	'560': '#171141',
	'590': '#16103d',
	base: '#271D6C',
} as const;
export const diepgroen = {
	'30': '#e2f1ee',
	'60': '#c5e2de',
	'90': '#a8d4cd',
	'300': '#027c69',
	'330': '#027765',
	'360': '#027160',
	'430': '#026556',
	'460': '#016051',
	'490': '#015a4d',
	base: '#02836F',
} as const;
export const mosgroen = {
	'100': '#ededde',
	'130': '#e8e8d4',
	'160': '#e3e3c9',
	'760': '#757a00',
	'790': '#676b00',
	'800': '#626600',
	'820': '#585c00',
	'860': '#454700',
	'920': '#272900',
	base: '#777c00',
	tint1: '#d6d7b2',
	tint2: '#ebebd9',
} as const;
export const paars = {
	'100': '#e7e1ea',
	'130': '#dfd8e4',
	'160': '#d8cede',
	'810': '#381150',
	'840': '#2f0e43',
	'900': '#1d092a',
	base: '#42145f',
	tint1: '#c6b8cf',
	tint2: '#e3dce7',
} as const;
export const violet = {
	'100': '#f2d9e7',
	'130': '#eecee0',
	'160': '#eac2d9',
	'690': '#9e005b',
	'720': '#8f0052',
	'780': '#700040',
	base: '#a90061',
	tint1: '#e5b2cf',
	tint2: '#f2d9e7',
} as const;
export const rood = {
	'80': '#f9dedc',
	'110': '#f6d2d0',
	'140': '#f4c6c3',
	'560': '#c5281c',
	'590': '#b7251a',
	'650': '#9c2016',
	'710': '#821a12',
	base: '#d52b1e',
	tint1: '#f2bfbb',
	tint2: '#f9dfdd',
} as const;
export const groen = {
	base: '#39870c',
	tint1: '#E1EDDA',
	tint2: '#C3DBB5',
} as const;

// Special colors:
export const kvk = {
	purple: '#AA418C',
	petrol: '#00526E',
	orange: '#FF9300',
} as const;
