import { violet } from '@dop/ui-primitives/color/colors';

import { OPColorTheme } from '../ColorTheme.types';
import { colorThemeBase } from '../globalDefinitions/colorThemeBase';

export const violetColorTheme: OPColorTheme = {
	name: 'Violet',
	project: 'OP',
	negative: {
		...colorThemeBase.negative,
		primary: {
			normal: violet[100],
			hover: violet[130],
			active: violet[160],
		},
	},
	positive: {
		...colorThemeBase.positive,
		primary: {
			normal: violet.base,
			hover: violet[690],
			active: violet[720],
		},
	},
	text: {
		...colorThemeBase.text,
		primary: {
			normal: violet.base,
			hover: violet[720],
			active: violet[780],
		},
	},
};
