import { BLOOMREACH_IMAGE_VARIANTS } from '@dop/shared/constants/bloomreach';

export const sharedConfig = {
	cbsApiBaseUrl: 'https://opendata.cbs.nl/ODataApi/odata',
	cbsSourceBase: 'https://opendata.cbs.nl/#/CBS/nl/dataset',
	staticRootPaths: [
		'/sitemap.xml',
		'/sitemapnews.xml',
		'/download/',
		'/includes/',
	],
	feedbackPathnameComponents: '/feedback/page-feedback',
	elasticRegioSuggestMaxResults: 7,
	imageVariants: Object.values(BLOOMREACH_IMAGE_VARIANTS),

	carrouselTypes: {
		listBlock: 'listBlock',
		promoBlock: 'promoBlock',
	},
	filterTypes: {
		aantal: 'aantal',
		regio: 'regio',
		onderwerp: 'onderwerp',
		alleenVanaf: 'alleen-vanaf',
		alleenTot: 'alleen-tot',
		plaats: 'plaats',
		gemeente: 'gemeente',
		waterschap: 'waterschap',
		provincie: 'provincie',
	},
} as const;
