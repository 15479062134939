import { diepgroen } from '@dop/ui-primitives/color/colors';

import { OPColorTheme } from '../ColorTheme.types';
import { colorThemeBase } from '../globalDefinitions/colorThemeBase';

export const diepgroenColorTheme: OPColorTheme = {
	name: 'Diepgroen',
	project: 'OP',
	negative: {
		...colorThemeBase.negative,
		primary: {
			normal: diepgroen[30],
			hover: diepgroen[60],
			active: diepgroen[90],
		},
	},
	positive: {
		...colorThemeBase.positive,
		primary: {
			normal: diepgroen[300],
			hover: diepgroen[330],
			active: diepgroen[360],
		},
	},
	text: {
		...colorThemeBase.text,
		primary: {
			normal: diepgroen[430],
			hover: diepgroen[460],
			active: diepgroen[490],
		},
	},
};
